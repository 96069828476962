* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

tr,
th,
td,
table {
  border: 1px solid rgb(136, 136, 136);
  border-collapse: collapse;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  tr,
  th,
  td,
  table {
    font-size: 12px;
    padding: 5px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Acme&family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&family=Croissant+One&family=Great+Vibes&family=Mooli&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rye&display=swap");
